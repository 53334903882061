import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const HomeContainer = React.lazy(() => import('./containers/home'));

function App() {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <div className={'app-container'}>
        <iframe src="https://ciqtracking.com/p/v/1/620c349ef87081249889e2b2/format/iframe?" frameBorder="0" width="1" height="1"></iframe>
        <Router>
          <Switch>
            <Route path={'/'}>
              <HomeContainer />
            </Route>
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
