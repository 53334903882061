import React from 'react';
import { hydrate, render } from 'react-dom';

import { SWRConfig } from 'swr';
import swrConfig from './swr';

import App from './App';
import './styles/main.scss';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(
    <SWRConfig value={swrConfig}>
      <App />
    </SWRConfig>,
    rootElement
  );
} else {
  render(
    <SWRConfig value={swrConfig}>
      <App />
    </SWRConfig>,
    rootElement
  );
}
